.allInOneContainer {
  padding: 120px 0;
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  background-color: var(--white);
}
.cardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 4rem;
}
.singleCard {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  background: var(--fill-selection-only);
  min-width: 340px;
  max-width: 340px;
  gap: 20px;
}

.imageContainer {
  min-height: 68px;
  min-width: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 16px;
  background: var(--neutrals-white, #fff);
}
.cardImg {
  min-height: 36px;
  min-width: 36px;
}
.cardDesc {
  color: #191653;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.0125rem;
}

@media screen and (max-width: 700px) {
  .allInOneContainer {
    width: 90%;
  }
  .singleCard {
    min-width: 100%;
    max-width: 100%;
  }
}