.PDPA {
  padding: 10rem 1.5rem;
  overflow: hidden;
}

.PDPA .container {
  margin: 0 auto;
  max-width: var(--container-width-primary);
}

.PDPA .container .pageTitle {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: center;
  letter-spacing: -0.4px;
  color: var(--black);
}

.PDPA .container .content {
  margin-top: 7.5rem;
}

.PDPA .container strong {
  font-weight: 700;
}

.PDPA .container a {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 700;
}

.PDPA .container p {
  margin-bottom: 1rem;
}

.PDPA .container ol {
  padding-left: 2rem;
}

.PDPA .container em {
  font-style: italic;
}

.PDPA .container h2 {
  font-size: 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}

.PDPA .container h3 {
  font-size: 2rem;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}

.PDPA .container h4 {
  font-size: 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}

.PDPA .container .content .subHeading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
  margin-top: 2.5rem;
}

.PDPA .container .content p {
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.list {
  list-style-type: decimal;
  margin-bottom: 1rem;
}
.list li {
  font-size: 16px;
  line-height: 1.5;
}
@media (max-width: 768px) {
  .PDPA .container .content {
    margin-top: 5rem;
  }

  .PDPA .container .pageTitle {
    font-size: 2.5rem;
  }

  .PDPA .container .content .subHeading {
    font-size: 1.875rem;
  }
}

@media (max-width: 500px) {
  .PDPA .container .content {
    margin-top: 3rem;
  }

  .PDPA .container .pageTitle {
    font-size: 1.875rem;
  }

  .PDPA .container .content .subHeading {
    font-size: 1.25rem;
  }

  .PDPA .container .content p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
