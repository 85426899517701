.navigationListMainContainer {
  position: fixed;
  top: 69px;
  left: 0;
  overflow: hidden;
  background: linear-gradient(to right, #fff 50%, #f3f5fd 50%);
  width: 100%;
  min-height: 200px;
  box-shadow: 8px 12px 24px 0px #12121214;
  z-index: 10;
}
.overlayBackground {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  cursor: auto;
}
.navLinksAndTabsContainer {
  max-width: var(--max-screen-width);
  margin: 0 auto;
  width: 100%;
  /* padding: 20px; */
  min-height: 200px;
  display: flex;
}
.navLinksAndTabs {
  display: flex;
  cursor: auto;
}
.heading {
  color: var(--text-tertiary);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-22);
  letter-spacing: 0.2px;
}
.plr12 {
  padding: 0 12px;
}
.leftListContainer {
  padding: 24px 32px 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
  min-width: 300px;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.list {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 32px 12px 12px;
  border-bottom: 1px solid var(--p-10);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-22);
  letter-spacing: 0.2px;
  color: var(--text-primary);
}
.list:hover {
  color: var(--p-50);
  border-radius: 8px;
  background-color: var(--fill-selection-only);
}
.rightCardContainer {
  background-color: #f3f5fd;
  padding: 24px 0px 60px 24px;
  flex-shrink: inherit;
}
.customerCard {
  background-color: var(--white);
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--text-primary);
}
.customerCardFooter {
  display: flex;
  align-items: center;
  gap: 16px;
}
.blogCardContainer {
  display: flex;
  gap: 32px;
}
.blogCard {
  max-width: 405px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.blogCard img {
  height: auto;
}
.cardText {
  margin-top: 8px;
  color: var(--text-primary);
}
.cardLinkText {
  display: flex;
  align-items: center;
  color: var(--link-rest);
  gap: 6px;
}
.cardLinkText:hover {
  text-decoration: underline;
}
.mobList {
  display: flex;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 990px) {
  .list {
    align-items: flex-start;
  }
  .blogCardContainer {
    flex-direction: column;
  }
  .rightCardContainer {
    background-color: var(--white);
    margin: 12px 0px 12px 24px;
    padding: 0 0 12px 0;
    flex-shrink: inherit;
    border-bottom: 1px solid var(--stroke-tabs-sections);
  }
}
