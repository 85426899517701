.navbar {
  width: 100%;
  background: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 109;
  box-shadow: 8px 12px 24px 0px #12121214;
  border-bottom: 1px solid var(--stroke-tabs-sections);
}
.navbarInnerContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;

}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.actionBtnContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.btnIconBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

@media screen  and (max-width: 1284px){
  .navbarInnerContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .navbar {
    /* width: var(--container-responsive-width); */
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.companyLogo {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 800px) {
  .navbar {
    /* width: var(--container-responsive-width); */
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .navbarInnerContainer {
    max-width: 100%;
    height: 56px;
    padding: 12px 24px;
  }
  .companyLogo {
    height: 32px;
    width: auto;
  }
  .actionBtnContainer {
    display: none;
  }
}

