.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.primary {
  min-height: 36px;
  color: var(--white);
  padding: 6px 10px;
  background: var(--p-50);
  border-radius: 8px;
  font-size: var(--font-size-14);
  line-height: var(--line-height-18);
  font-weight: var(--font-weight-600);
  letter-spacing: -0.4px;
}

.secondary {
  padding: 5px 10px;
  min-height: 36px;
  border-radius: 8px;
  background-color: var(--white);
  color: var(--p-50);
  border: 1px solid var(--p-50);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  font-weight: var(--font-weight-600);
  letter-spacing: 0.2px;
}
.tertiary {
  padding: 5px 10px;
  min-height: 64px;
  min-width: 175px;
  border-radius: 20px;
  background-color: transparent;
  color: var(--black);
  border: 1px solid var(--d-40);
  font-size: var(--font-size-18);
  line-height: var(--line-height-23);
  font-weight: var(--font-weight-600);
}

@keyframes iconMove {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

.btn:hover .iconAnimate {
  animation: iconMove 2s infinite;
}
