.allInOneContainer {
  position: relative;
  padding: 120px 0;
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
}
.activeBtnParent {
  position: absolute;
  max-width: 100%;
  overflow: scroll;
  margin-bottom: 64px;
  top: 0px;
  z-index: 20;

}
.activeBtnParent {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.activeBtnParent::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.activeBtnContainer {
  /* padding-top: 120px; */
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 1300px;
}

.singleBtn {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 230px;
  min-width: 220px; */
  min-width: 236px;
  max-width: 236px;
  padding: 16px;
  border-radius: 16px;
  border-radius: 16px;
  border: 1px solid var(--stroke-selection, #d3dbf8);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  cursor: pointer;
}
.singleActiveBtn {
  background: var(--p-50);
  color: white;
}
.line {
  min-width: 30px;
  height: 1px;
  max-width: 30px;
  border: 1px solid var(--stroke-selection, #d3dbf8);
}
.singleCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
}
.clientImage {
  height: 100%;
  width: 100%;
}
.detailsContainer {
  max-width: 770px;
}
.title {
  color: var(--text-primary);
  text-align: center;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.75rem; /* 122.222% */
  letter-spacing: -0.025rem;
  margin-top: 24px;
}

.subTitle {
  color: var(--text-primary);
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem; /* 140% */
  letter-spacing: 0.0125rem;
  margin-top: 10px;
}
.cardLogo,
.cardLogoImageContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  gap: 10px;
}
.dot {
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--p-10);
}
.filledDot {
  background: var(--p-50);
}

.changeIcon {
  opacity: 0.7;
  cursor: pointer;
}
.changeIcon:hover {
  opacity: 1;
}
.changeIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  opacity: 0.5;
  background: var(--fill-selection-only);
}
.changeIconContainer:hover {
  border-radius: 8px;
  opacity: 1;
  background: var(--fill-selection-only);
}

@media screen and (max-width: 1200px) {

  .singleBtn {
  min-width: 210px;
  max-width: 210px;
  }
}

@media screen and (max-width: 930px) {
.reviewCardContainer {
  width: 90%;
  margin: auto;
}
  .singleBtn {
  min-width: 210px;
  max-width: 210px;
  }
}