.navigationListMainContainer {
  position: fixed;
  top: 69px;
  left: 0;
  overflow: hidden;
  background: linear-gradient(to right, #fff 50%, #f3f5fd 50%);
  width: 100%;
  min-height: 200px;
  box-shadow: 8px 12px 24px 0px #12121214;
  z-index: 10;
}
.overlayBackground {
  position: fixed;
  top: 69px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  cursor: auto;
}
.navLinksAndTabsContainer {
  max-width: var(--max-screen-width);
  margin: 0 auto;
  width: 100%;
  /* padding: 20px; */
  min-height: 200px;
  display: flex;
  justify-content: space-between;
}
.navLinksAndTabs {
  display: flex;
  width: 100%;
  cursor: auto;
}
.tabLinksContainer {
  background-color: var(--fill-highlight-only);
  width: 67%;
}
.tabContainer {
  width: 33%;
  padding: 24px 16px 0 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.tab {
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px 10px;
  width: 100%;
  border-bottom: 1px solid var(--stroke-tabs-sections);
  border-radius: 4px;
  cursor: pointer;
  color: var(--text-primary);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-20);
}
.tab:hover {
  background-color: var(--fill-highlight-only);
}
.activeTab {
  color: var(--p-50);
  background-color: var(--fill-highlight-only);
}
.tabInnerArea {
  display: flex;
  align-items: center;
  gap: 8px;
}
.rightSideCard {
  max-width: 324px;
  padding: 12px 32px;
  margin: 24px 0 48px 0;
  background-color: #f3f5fd;
  display: flex;
  flex-direction: column;
  gap: 24px;
  cursor: auto;
}
.rightSideCard img {
  height: auto;
}
.rightSideInnerCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.rightSideCardTextDetail {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--text-primary);
}
.rightSideCardText {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-22);
  color: var(--text-secondary);
}
.cardLinkText {
  display: flex;
  align-items: center;
  color: var(--link-rest);
  gap: 6px;
}
.cardLinkText:hover {
  text-decoration: underline;
}
.linkBox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 12px;
  border: 1px solid var(--fill-highlight-only);
  border-radius: 8px;
}
.linkBox:hover {
  border-color: var(--p-10);
  background-color: var(--fill-selection-only);
}
.linkBox:hover .linkTitle {
  color: var(--p-50);
}
.linkTextDetails {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.linkTitle {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-22);
  letter-spacing: 0.2px;
  color: var(--text-primary);
}
.navListContainer {
  display: flex;
  padding: 24px 16px;
  gap: 12px;
}

@media screen and (max-width: 990px) {
  .navLinksAndTabs,
  .navLinksAndTabsContainer,
  .navListContainer {
    flex-direction: column;
  }
  .navListContainer,
  .tabContainer, .tab {
    padding: 0;
  }
  .tabContainer {
    gap: 8px;
  }
  .tab {
    border-radius: 0;
    border: none;
  }
  .innerTab {
    padding: 12px;
    border-bottom: 1px solid var(--stroke-tabs-sections);
  }
  .tabLinksContainer,
  .tabContainer {
    width: 100%;
  }

  .mobTabAreaContainer {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .rightSideCard {
    max-width: 100%;
    margin: 20px 12px 12px 12px;
    padding: 0 0 12px 12px;
    background-color: transparent;
    border-bottom: 1px solid var(--stroke-tabs-sections);
  }
}
