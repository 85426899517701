.parentContainer {
  background: var(--fill-selection-only);
}

.allInOneContainer {
  padding: 120px 0;
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
}
.otherContainer {
  margin-top: 64px;
}

.zenAdminOffersImage {
  width: 100%;
  height: 100%;
}
.productContainer {
  display: none;
}

.productTitle {
  border-radius: 4px;
  background: var(--stroke-buttons-input);
  color: var(--p-50);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem; /* 125% */
  padding: 8px 12px;
  width: fit-content;
  margin: 28px 0 16px 0;
}
.servicesTitle {
  border-radius: 4px;
  background: var(--i-10);
  color: var(--i-50);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 8px 12px;
  width: fit-content;
  margin: 28px 0 16px 0;
}
.singleItem {
  display: flex;
  gap: 10px;
  align-items: center;
}
.imageParent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--p-10);
}
.itemImg {
  height: 20px;
  width: 320px;
}
.itemDesc {
  color: #191653;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.0125rem;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.imageContainer2 {
  margin-top: 4rem;
  width: 90%;
}
.zenAdminOffersMobImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 700px) {
  .otherContainer {
    display: none;
  }
  .productContainer {
    display: block;
    width: 90%;
    margin-left: 40px;
  }
}
