.heroSection {
  width: 100%;
  min-height: auto;
  height: 100%;
  /* background: radial-gradient(circle at top center, #4e86cb6e,#fff  70%),
    radial-gradient(circle at bottom center, #4e86cb6e, #fff 70%); */
  background-image: url('../../../assets/icons/home-page/hero/hero-bg-imag.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 80px 0 60px 0;
}
.innerHeroSection {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 68px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  padding-bottom: 60px;
}
.heroTopSection {
  width: 74%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 48px;
}
.topInnerSection {
  max-width: 848px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
}
.ctaContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}
.btnIconBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 32px;
  height: 32px;
  border-radius: 12px;
}
.bookDemoBtn {
  border-radius: 16px;
  color: var(--p-50);
  background-color: var(--p-10);
  padding: 20px 24px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
}
.heroVideo {
  max-width: 1260px;
  width: 100%;
  border-radius: 16px;
}
.heroMarqueeContainer {
  gap: 50px;
}
.marqueeLogoBox {
  /* margin-right: 50px; */
  height: 32px;
  width: 180px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.marqueeLogoBox img {
  width: 100%;
}
.newSectionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.onlyImgContainer {
  position: relative;
  height: 305px;
  width: 305px;
  cursor: pointer;
}
.onlyImgContainer:hover .overlappingInfoContainer {
  bottom: 0;
  height: 100%;
  z-index: 2;
}
.onlyImgContainer:hover .overlappingInfoParent {
  bottom: 0;
  height: 100%;
  z-index: 2;
}
.overlappingInfoParent {
  display: block;
  position: absolute;
  width: 100%;
  z-index: -1;
  border-radius: 16px;
  background-color: white;
  bottom: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 0;
  transition: 0.5s ease;
}
.overlappingInfoContainer {
  display: flex;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(37, 77, 218, 0.5) 0%, #254dda 50%);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 24px;
  gap: 16px;
  bottom: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 0;
  transition: 0.5s ease;
}
.overlayTitle {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  letter-spacing: -0.025rem;
}
.overlaySubTitle {
  color: white;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
}

.benefitImg {
  width: 100%;
  height: 100%;
}
.fullDetailsContainer {
  display: none;
}
@media screen and (max-width: 800px) {
  .heroSection {
    min-height: fit-content;
    padding-top: 56px;
  }
  .heroTopSection {
    width: 100%;
  }
  .innerHeroSection {
    padding: 0 24px;
  }
}

@media screen and (max-width: 500px) {
  .bookDemoBtn {
    width: 100%;
  }
  .heroSection {
    padding: 56px 0 40px 0;
  }
  .heroMarqueeContainer {
    margin-top: 40px;
  }
  .ctaContainer {
    flex-direction: column;
  }
  .onlyImgContainer {
    display: none;
  }
  .fullDetailsContainer {
    display: block;
    border-radius: 25px;
    background: #d3dbf8;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  .imageTitle {
    padding: 22px;
    color: var(--text-primary);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.875rem;
    letter-spacing: -0.025rem;
  }
  .imageSubTitle {
    padding: 0 22px 22px;
    color: var(--text-primary);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
  }
  .newSectionBox {
    gap: 20px;
  }
}
