.customModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  min-width: 200px;
  min-height: 100px;
  width: 550px;
}

@media screen and (max-width: 800px) {
  .modalContent {
    width: auto;
  }
}
